import { useEffect, useState } from "react"
import { IUserProps } from "../contexts/user/user-context"
import { client } from "../data/apollo/apollo-client"
import { GET_USER_QUERY } from "../data/queries/get-user-query"
import { GET_V2_USER_QUERY } from "../data/queries/GET_V2_USER_QUERY"
import { activeChartIds } from "../utils/active-chart-ids"

export function getIsPaid(subs: any[]) {
  if (!subs || subs?.length < 1) {
    return false
  }

  if (subs?.[0]?.product_id === "No subscription") {
    return false
  }
  return true
}

export function getIsTrialing(subs: any[]) {
  const trialEndDate = new Date(Number(subs?.[0]?.trial_end))

  return !(trialEndDate < new Date())
}

export function useGetUser(id: string): {
  loading: boolean
  error: Error | null
  data: IUserProps | null
} {
  const [state, setState] = useState({ loading: true, error: null, data: null })

  useEffect(() => {
    async function getUser() {
      const { data } = await client.query({
        query: GET_USER_QUERY,
        variables: { id },
      })
      const { User } = data
      console.log("use get user", User)
      setState({ loading: false, error: null, data: User })
    }
    getUser()
  })

  return state
}

export async function fetchUser(token: string): Promise<Partial<IUserProps>> {
  const { data } = await client.query({
    query: GET_V2_USER_QUERY,
    variables: { token },
    context: {
      headers: { xxx: token },
    },
  })

  const { User } = data

  if (User) {
    const { isPaid, layouts, ...user } = User
    delete user.__typename
    const paid = isPaid
    const trialing = false
    const access = paid
    const userLayouts = layouts ? JSON.stringify((JSON.parse(layouts) || {})?.data || "") : ""
    return {
      ...user,
      layouts: userLayouts,
      paid,
      trialing,
      access,
      workspaces: user?.workspaces?.map(ws => {
        return {
          ...ws,
          data: returnWsDataObject(ws?.data),
        }
      }),
      workspaceIds: getWorkspaceIds(user?.workspaces || []),
      currentWorkspace: user?.workspaces?.length > 0 ? user?.workspaces?.[0]?.id : "",
    }
  }
  return {}
}

export function returnWsDataObject(wsData: any | string = {}): any {
  while (typeof wsData === "string") {
    wsData = JSON.parse(wsData)
    if (typeof wsData !== "string") {
      return { ...wsData }
      break
    }
  }
  return { ...wsData }
}

export function getWorkspaceIds(workspaces = []) {
  const allIds = []
  for (const ws of workspaces) {
    const wsData = returnWsDataObject(ws.data)
    for (const component of wsData?.components || []) {
      const compId = component.meta.id
      if (activeChartIds.includes(compId)) {
        allIds.push(compId)
      }
    }
  }

  return allIds
}
