export const activeChartIds = [
    // Binance Futures Instrument
    "a31405bd-d1e8-4327-92cd-2273d73bfbbf",
    "5ef7e06c-1a7a-4043-911b-51a74b81d741",
    // Binance Futures
    "77ea29cd-e34f-4795-93ac-8911a020e9d4",
    // Binance Perpetual
    "5acec732-347c-46cc-a9c9-552cfee81324",
    // Bitmex Futures Instrument
    "d8b95ee1-59f7-4aee-a077-3209ef1c8290",
    "f500fec2-6f83-4b5a-af00-3dcf04922297",
    // Bitmex Futures
    "fbc5af1a-eeb2-4412-b8e3-0ab4d14255ce",
    // Bybit Futures Instrument
    "eb66e120-073e-4e06-847e-38445dd8206c",
    "545f3ff2-1657-456d-9d1f-b431ab0c1309",
    // Bybit Futures
    "d9a5a225-bea3-45ee-8054-46eba680ba56",
    // Bybit Perpetual
    "de9671d6-e857-11ec-8fea-0242ac120002",
    // CME Futures
    "2ccc27a3-d61c-4de8-954f-3fa199a76e85",
    "9ea1f224-e1b5-4559-92e1-aef4f1975855",
    "34671b07-d82f-4595-8d6f-4154163f974a",
    "2ccc27a3-d61c-4de8-954f-3fa199a76e84",
    "1ccc27a3-d61c-4de8-854f-3fa199a76e73",
    // Coinshares Futures
    "4fc3ab62-4469-43f2-a68c-50e3de2ec27e",
    "84fadca4-62f5-45fe-b7a9-8f81db189612",
    "519e1e97-333b-4bc2-8ab9-8ab0f5d3cd88",
    "5aa9ed87-fd45-4b78-a2c9-010ea28afd27",
    "4b308f08-b220-4849-a2cd-89f0ecc5924f",
    "70dd9102-071a-4949-b9d1-01974eb57179",
    "71aa5e2a-7a1e-4da8-ae1f-533566c1422e",
    "46930cba-83e3-4b62-a83d-234c6e284819",
    "52ef9da1-9cdd-4f8e-9453-db85fd1d3ef5",
    "8aa22b3b-6156-48a5-9c9d-9a1d0f5a355b",
    "0f5e0d21-bb37-41a6-90d7-dc4b097ff7fc",
    "34671b07-d82f-4595-8d6f-4154163f974a",
    "410f2f03-b68b-4f3f-bb2c-c7897977b06d",
    "38228d07-7e2b-4170-a387-eca6250290d4",
    "2cbc27a3-d61c-4de7-954f-3fa199a76e84",
    // Deribit Futures Instrument
    "3d548b95-4f05-4d1b-8869-582acbba6654",
    "1fbfe445-5f32-4a50-affb-fac29b49e05f",
    // Deribit Futures
    "8da83407-1cdf-4ffc-8a58-26be0bbf96c7",
    // Deribit Perpetual
    "5acec732-347c-46cc-a9c9-552cfee81324",
    // Futs Perps Global
    "5cca4380-f54a-11ec-b939-0242ac120002",
    "00eb5244-f599-11ec-b939-0242ac120002",
    "10f8b77e-f59b-11ec-b939-0242ac120002",
    "56a486e2-f59e-11ec-b939-0242ac120002",
    // Huobi Futures Instrument
    "9a35e92f-fd4f-46e8-8cf7-30416240ec98",
    "dbaf2ee8-312f-4f29-90ed-2709baa0467d",
    // Huobi Futures
    "bb11bcee-a142-432a-bfec-66db91a79c39",
    // Kraken Futures Instrument
    "d4b527e3-37bc-42a7-b689-e4bf23bbec89",
    "c87311c1-52f8-4814-a4ba-2687b2a3274a",
    // Kraken Futures
    "a3db088f-3e89-4a80-b030-ef172bcbf802",
    // Okex Futures Instrument
    "e7f6757f-d11d-4d43-8d26-203a653c0a4b",
    "aef75780-ca15-4367-99a6-638bbbd55e31",
    // Okex Futures
    "fc4f40ab-14b0-4544-959d-3005f6f1e0ba",
    // Okex Perpetual
    "5acec732-347c-46cc-a9c9-552cfee81324",
    // Thalex Future Rolls
    "687873b5-23da-4d71-9d19-d825ceb52f69",
    // Thalex Futures
    "687873b5-23da-4d71-9d19-d825ceb52f69",
    // Thalex Perpetual
    "b9fdab82-a939-4bdb-a1ec-e676fcf45215",
    // Bybit Options Current
    "64c9d20e-1365-40fa-a89a-7f204105606f",
    "1981deac-1498-4452-8483-0432b25da366",
    "c76507bc-5a65-4186-aae4-c10ca49bf967",
    "494717aa-0d85-49b9-92b0-10eb9c9be774",
    "a154108c-e873-11ec-8fea-0242ac120002",
    "fe7c5885-bcb0-4592-8b4d-ae012ee21071",
    "bb15413c-d927-4f26-8c48-725ce847548e",
    "232g9-215b-0dc-3b31-14a3c6ed0",
    "56ee4f-aa0-6d74-464-544c25c8c604",
    "41512f1-ae12-b5b-a1b7-f67c1484b0",
    "7458e35-afd-77a6-2c5a-8ba2282b7a1",
    "f08ad3e-e0ab-33db-0347-c7a48fb6a8e",
    "4b0a06-f326-afb6-0a7c-efcf8cebd0",
    "b7d1d-3080-48d-43-c1365b36156f",
    "28b8b1f-ad3-6006-d72f-8da8c4a77a1",
    "3a6aee5-552b-0bdb-85ab-32eac148efaf",
    "cc2b0fa-3ee3-475-eb8-0b36a117670",
    "a2158c-de8-33bc-bee-406604acd474",
    "3ea48c1-05eb-653a-3ac1-acf256e687d4",
    "4d6f721-44-e72d-f754-51105428ac44",
    "fe5c9042-9af7-48fa-afaf-cbbe500a5947",
    // Bybit Options Historical
    "3a9dc057-e74c-4fae-9749-a752a98adfa8",
    "ad6c120f-accb-44ab-b486-3f008144c2cc",
    "15fda9b8-a04c-47ec-9fd4-9f5bcfcab5d0",
    "2382022f-42e4-41e2-b7d2-7e9621720675",
    "34edf03-3dfc-5523-316b-ac7d6f44a8dd",
    "f0f17-db1c-7b64-c4a1-6b2724fc583",
    "55cb96f2-75ee-48af-a4f8-8d90d37749a3",
    "67dd5e-aa0-6d74-464-544c25c8c604",
    "ce7ebfe-0627-ba1-06a0-30f55b6f714",
    "38f768-e103-14c-a740-406d776a31",
    "a78f4f-dd8-dbeb-530a-f5ed0ccfca3",
    "55cb96f2-75ee-48af-a4f8-8d90d37749a8",
    "accca072-7f29-42f1-a6f6-7a00461cb419",
    // Deribit Currency Relative Vol
    "1981deac-1498-4452-8483-0432b25da367",
    "bb15413c-d927-4f25-8c48-725ce847548f",
    "4cb4a1b9-b8af-4e53-8fd5-c346c8486a38",
    "455cc501-4987-4c9f-b982-aea266781b0f",
    "2f805b2e-3d1b-4196-82aa-4fd9ac8d4198",
    "da04dd91-6ec4-4ebe-ae36-ed585feb6248",
    // Deribit Options Current
    "56ee4f-aa0-6d74-464-544c25c8c604",
    "64c9d20e-1365-40fa-a89a-7f204105606e",
    "1981deac-1498-4452-8483-0432b25da365",
    "c76507bc-5a65-4186-aae4-c10ca49bf968",
    "494717aa-0d85-49b9-92b0-10eb9c9be773",
    "a154108c-e873-11ec-8fea-0242ac120001",
    "fe7c5885-bcb0-4592-8b4d-ae012ee21072",
    "bb15413c-d927-4f26-8c48-725ce847548f",
    "343f8-215b-0dc-3b31-14a3c6ed1",
    "67dd5e-aa0-6d74-464-544c25c8c603",
    "41512f1-ae12-b5b-a1b7-f67c1484b1",
    "7458e35-afd-77a6-2c5a-8ba2282b7a2",
    "f08ad3e-e0ab-33db-0347-c7a48fb6a8f",
    "4b0a06-f326-afb6-0a7c-efcf8cebd1",
    "b7d1d-3080-48d-43-c1365b36156e",
    "28b8b1f-ad3-6006-d72f-8da8c4a77a2",
    "3a6aee5-552b-0bdb-85ab-32eac148efae",
    "cc2b0fa-3ee3-475-eb8-0b36a117671",
    "a2158c-de8-33bc-bee-406604acd473",
    "3ea48c1-05eb-653a-3ac1-acf256e687d5",
    "4d6f721-44-e72d-f754-51105428ac43",
    "fe5c9042-9af7-48fa-afaf-cbbe500a5946",
    "eba57d5a-fbb2-4642-bf88-bed4ff7049b1",
    "232g9-215b-0dc-3b31-14a3c6ed1",
    // Deribit Historical
    "3a9dc057-e74c-4fae-9749-a752a98adfa7",
    "ad6c120f-accb-44ab-b486-3f008144c2cd",
    "15fda9b8-a04c-47ec-9fd4-9f5bcfcab5d1",
    "2382022f-42e4-41e2-b7d2-7e9621720672",
    "34edf03-3dfc-5523-316b-ac7d6f44a8dd",
    "f0f17-db1c-7b64-c4a1-6b2724fc583",
    "55cb96f2-75ee-48af-a4f8-8d90d37749a3",
    "67dd5e-aa0-6d74-464-544c25c8c603",
    "ce7ebfe-0627-ba1-06a0-30f55b6f713",
    "38f768-e103-14c-a740-406d776a32",
    "a78f4f-dd8-dbeb-530a-f5ed0ccfca4",
    "55cb96f2-75ee-48af-a4f8-8d90d37749a7",
    "accca072-7f29-42f1-a6f6-7a00461cb418",
    // Deribit Options Scanner
    "22c5e923-4653-4482-beb7-e00b2e552ae4",
    "6eae4d7f-8f6e-4fdc-b740-f86e82f7a981",
    "01eed714-d370-436f-b94e-f7eefd246141",
    "37af0b5-872a-a057-ca22-0f0314befd2",
    "b7d1d-3080-48d-43-c1365b36156f",
    "28b8b1f-ad3-6006-d72f-8da8c4a77a0",
    "a8c12cf-16c6-b5b5-a870-1bb5cae20b1",
    "4b3fd2f7-f29a-4fb3-8141-faa238138e4c",
    "f174f957-d451-46f8-8371-b18f0a9408de",
    "15654a4-b771-0e34-a0d5-de860ba15f45",
    "6932a222-658b-4fea-9829-cf4473024597",
    // Options Global Hifi
    "96904a22-ddb8-4a4c-8735-4c3f6cc3123e",
    "3a3b4fb4-c121-4795-8218-88d2fd5b27f8",
    "8422c4b6-9d80-49e2-b28b-8bfeff06e3c4",
    "4dee1497-f8f5-4499-b606-9fb03435a1fb",
    "57f497d6-77ca-4377-82ee-30621df55e82",
    "e7ac9c37-1209-43eb-bd25-1e3aa21c4ba8",
    "521644cd-c15e-41c2-bcfa-3c6e06050178",
    "b9483b36-b914-479e-bbcc-b795f2e05d77",
    "41c6a4df-ffd1-413d-b609-742be5655264",
    "ab598cd1-96ba-46bd-9f31-ceb5b89f8545",
    // Lyra Options Current
    "64c9d20e-1365-40fa-a89a-7f204105606f",
    "1981deac-1498-4452-8483-0432b25da366",
    "c76507bc-5a65-4186-aae4-c10ca49bf967",
    "494717aa-0d85-49b9-92b0-10eb9c9be774",
    "a154108c-e873-11ec-8fea-0242ac120002",
    "fe7c5885-bcb0-4592-8b4d-ae012ee21071",
    "bb15413c-d927-4f26-8c48-725ce847548e",
    "232g9-215b-0dc-3b31-14a3c6ed0",
    "56ee4f-aa0-6d74-464-544c25c8c604",
    "41512f1-ae12-b5b-a1b7-f67c1484b0",
    "7458e35-afd-77a6-2c5a-8ba2282b7a1",
    "f08ad3e-e0ab-33db-0347-c7a48fb6a8e",
    "4b0a06-f326-afb6-0a7c-efcf8cebd0",
    "b7d1d-3080-48d-43-c1365b36156f",
    "28b8b1f-ad3-6006-d72f-8da8c4a77a1",
    "3a6aee5-552b-0bdb-85ab-32eac148efaf",
    "cc2b0fa-3ee3-475-eb8-0b36a117670",
    "a2158c-de8-33bc-bee-406604acd474",
    "3ea48c1-05eb-653a-3ac1-acf256e687d4",
    "4d6f721-44-e72d-f754-51105428ac44",
    "fe5c9042-9af7-48fa-afaf-cbbe500a5947",
    // Lyra Options Historical
    "3a9dc057-e74c-4fae-9749-a752a98adfa8",
    "ad6c120f-accb-44ab-b486-3f008144c2cc",
    "15fda9b8-a04c-47ec-9fd4-9f5bcfcab5d0",
    "2382022f-42e4-41e2-b7d2-7e9621720675",
    "34edf03-3dfc-5523-316b-ac7d6f44a8dd",
    "f0f17-db1c-7b64-c4a1-6b2724fc583",
    "55cb96f2-75ee-48af-a4f8-8d90d37749a3",
    "56ee4f-aa0-6d74-464-544c25c8c604",
    "ce7ebfe-0627-ba1-06a0-30f55b6f714",
    "38f768-e103-14c-a740-406d776a31",
    // Okex Options Current
    "64c9d20e-1365-40fa-a89a-7f204105606f",
    "1981deac-1498-4452-8483-0432b25da366",
    "c76507bc-5a65-4186-aae4-c10ca49bf967",
    "494717aa-0d85-49b9-92b0-10eb9c9be774",
    "a154108c-e873-11ec-8fea-0242ac120002",
    "fe7c5885-bcb0-4592-8b4d-ae012ee21071",
    "bb15413c-d927-4f26-8c48-725ce847548e",
    "232g9-215b-0dc-3b31-14a3c6ed0",
    "56ee4f-aa0-6d74-464-544c25c8c604",
    "41512f1-ae12-b5b-a1b7-f67c1484b0",
    "7458e35-afd-77a6-2c5a-8ba2282b7a1",
    "f08ad3e-e0ab-33db-0347-c7a48fb6a8e",
    "4b0a06-f326-afb6-0a7c-efcf8cebd0",
    "b7d1d-3080-48d-43-c1365b36156f",
    "28b8b1f-ad3-6006-d72f-8da8c4a77a1",
    "3a6aee5-552b-0bdb-85ab-32eac148efaf",
    "cc2b0fa-3ee3-475-eb8-0b36a117670",
    "a2158c-de8-33bc-bee-406604acd474",
    "3ea48c1-05eb-653a-3ac1-acf256e687d4",
    "4d6f721-44-e72d-f754-51105428ac44",
    "fe5c9042-9af7-48fa-afaf-cbbe500a5947",
    // Okex Options Historical
    "3a9dc057-e74c-4fae-9749-a752a98adfa8",
    "ad6c120f-accb-44ab-b486-3f008144c2cc",
    "15fda9b8-a04c-47ec-9fd4-9f5bcfcab5d0",
    "2382022f-42e4-41e2-b7d2-7e9621720675",
    "34edf03-3dfc-5523-316b-ac7d6f44a8dd",
    "f0f17-db1c-7b64-c4a1-6b2724fc583",
    "55cb96f2-75ee-48af-a4f8-8d90d37749a3",
    "67dd5e-aa0-6d74-464-544c25c8c604",
    "ce7ebfe-0627-ba1-06a0-30f55b6f714",
    "38f768-e103-14c-a740-406d776a31",
    "a78f4f-dd8-dbeb-530a-f5ed0ccfca3",
    "55cb96f2-75ee-48af-a4f8-8d90d37749a8",
    "accca072-7f29-42f1-a6f6-7a00461cb419",
    // Thalex Options Current
    "64c9d20e-1365-40fa-a89a-7f204105606f",
    "1981deac-1498-4452-8483-0432b25da366",
    "c76507bc-5a65-4186-aae4-c10ca49bf967",
    "494717aa-0d85-49b9-92b0-10eb9c9be774",
    "a154108c-e873-11ec-8fea-0242ac120002",
    "fe7c5885-bcb0-4592-8b4d-ae012ee21071",
    "bb15413c-d927-4f26-8c48-725ce847548e",
    "232g9-215b-0dc-3b31-14a3c6ed0",
    "56ee4f-aa0-6d74-464-544c25c8c604",
    "41512f1-ae12-b5b-a1b7-f67c1484b0",
    "7458e35-afd-77a6-2c5a-8ba2282b7a1",
    "f08ad3e-e0ab-33db-0347-c7a48fb6a8e",
    "4b0a06-f326-afb6-0a7c-efcf8cebd0",
    "b7d1d-3080-48d-43-c1365b36156f",
    "28b8b1f-ad3-6006-d72f-8da8c4a77a1",
    "3a6aee5-552b-0bdb-85ab-32eac148efaf",
    "cc2b0fa-3ee3-475-eb8-0b36a117670",
    "a2158c-de8-33bc-bee-406604acd474",
    "3ea48c1-05eb-653a-3ac1-acf256e687d4",
    "4d6f721-44-e72d-f754-51105428ac44",
    "fe5c9042-9af7-48fa-afaf-cbbe500a5947",
    // Thalex Options Historical
    "3a9dc057-e74c-4fae-9749-a752a98adfa8",
    "ad6c120f-accb-44ab-b486-3f008144c2cd",
    "15fda9b8-a04c-47ec-9fd4-9f5bcfcab5d1",
    "2382022f-42e4-41e2-b7d2-7e9621720675",
    "34edf03-3dfc-5523-316b-ac7d6f44a8de",
    "f0f17-db1c-7b64-c4a1-6b2724fc582",
    "55cb96f2-75ee-48af-a4f8-8d90d37749a2",
    "67dd5e-aa0-6d74-464-544c25c8c603",
    "ce7ebfe-0627-ba1-06a0-30f55b6f714",
    "38f768-e103-14c-a740-406d776a31",
    "a78f4f-dd8-dbeb-530a-f5ed0ccfca3",
    "55cb96f2-75ee-48af-a4f8-8d90d37749a8",
    "accca072-7f29-42f1-a6f6-7a00461cb419",
    // Greeks
    "8e70579e-1d5c-4312-9407-e26402cca658",
    "fcfa3cd9-e81a-42a5-8726-059ab42b1ed0",
    "b6c0d806-96f4-4547-b864-c5c23c0c5de7",
    "f8a672bc-18e6-4c30-bc8e-72f16b4f7dfe",
    "4450524e-d5d4-48f9-b9c8-48eb343b8e3e",
    // DVol Index
    "be0ad82b-f3f1-40d4-a8ff-93bf3b01a3b76",
    "ab84e25a-84e2-495b-85a0-6368b6e4c425",
    "a5779007-c39a-4a9f-80b1-e55c6fb42b80",
    "adc3783-e03-d20-f513-6e07d378ec2",
    "dda1e71-3827-6844-7f6d-0bdbca3fb2",
    "0d2e8a2-44dd-c16b-ca1f-e35baff744c",
    "cd1726-5bd1-6677-7ad5-03b27d86ae",
    // Performance
    "b496ddd1-57c5-448f-8f84-5edaa69b4582",
    // Real Vol
    "7b028958-fdeb-4c5f-b5e2-b4fa080e2347",
    "00eaf0e0-e87e-489c-ba9e-d1afadfbff05",
    "ccfbcffa-ede0-11ec-8ea0-0242ac120001",
    "37929f34-eda4-11ec-8ea0-0242ac120001",
    "102e360e-edba-11ec-8ea0-0242ac120001",
    "11dc97fe-edd1-11ec-8ea0-0242ac120001",
    "4cb4a1b9-b8ae-4e53-8fd5-c346c8486a38",
    "c57cc656-f256-11ec-b939-0242ac120001",
    // Thalex Instrument Futures
    "85a9960a-df36-4f6a-82cb-18671e4219cf",
    "9d0a4a37-5c99-430f-ab7b-87f1467de3a6",
    "35a3390b-92d2-4690-9865-2dd8b98a8571",
    // Thalex Instrument Perpetuals
    "a1a1bef3-4a45-4afa-aad4-1d07554a60ba",
    "5857e9e9-9350-4584-86cc-9d495cf14bf6",
    "0f9da226-81a1-4bb9-8538-888848484605",
    "663e7151-874b-43cc-8ea2-577f2be40695",
    // Yields
    "a4203c4e-516c-45cf-a0d7-26f35f45a692",
]