import React, { createContext, FC, useReducer, useEffect, useState } from "react"
import { LoadingPage } from "../../components/loading-page/loading-page"
import { useFirebase } from "../../hooks/use-firebase"
import { isBrowser } from "../../utils/is-browser"
import { getItem, parseObj } from "../../utils/token-helpers"
import { useNotifications } from "../../hooks/use-notifications"
import { getFullUser } from "./auth-helpers/get-full-user"
import { updateUserSubscriptionStatus } from "./auth-helpers/update-user-subscriptions"
import { postConsoleMessage } from "./post-console-message"
import { getGvolTheme } from "../../theme/theme-colors"

import {
  addComponentToWorkspace,
  removeComponentToSelectedWorkspace,
  saveAndReturnState,
} from "./workspace-helpers/workspace-helpers"
import { getWorkspaceIds } from "../../hooks/use-get-user"
import { onAuthStateChanged } from "firebase/auth"

export const USER_KEY = "GVOL_USER_v2"

export const GlobalUserState = createContext<IUserProps>(null)
export const GlobalUserDispatch = createContext(null)

export const userActions = {
  SET_USER: "SET_USER",
  SET_WORKSPACE_LOADING: "SET_WORKSPACE_LOADING",
  SET_WORKSPACE_DASHBOARD: "SET_WORKSPACE_DASHBOARD",
  UPDATE_USER: "UPDATE_USER",
  ADD_WORKSPACE_DASHBOARD: "ADD_WORKSPACE_DASHBOARD",
  ADD_TO_WORKSPACE: "ADD_TO_WORKSPACE",
  REMOVE_FROM_WORKSPACE: "REMOVE_FROM_WORKSPACE",
  REMOVE_WORKSPACE_DASHBOARD: "REMOVE_WORKSPACE_DASHBOARD",
  UPDATE_USER_THEME: "UPDATE_USER_THEME",
  SET_CURRENT_WORKSPACE: "SET_CURRENT_WORKSPACE",
  RESET_USER: "RESET_USER",
}

type actionTypes = keyof typeof userActions

export interface IWorkspace {
  [key: string]: {
    id: string
    name: string
    data: string
  }
}

export interface IUserProps {
  isAuthenticated: boolean
  access: boolean
  isTrialing: boolean
  paid: boolean
  cryptoPaid: boolean
  loading: boolean
  loadingWorkspaces?: boolean
  emailVerified?: boolean
  userMetaData?: object
  name?: string
  email?: string
  token?: string
  photo?: string
  trialEnd?: string
  address?: string
  userTheme?: string
  theme?: any
  apiKeys?: { key: string; name: string; createdAt: string }[]
  currentWorkspace?: string
  workspaces?: any
  workspaceIds?: string[]
  layouts?: string
}

export const initialUser: IUserProps = {
  isAuthenticated: false,
  access: false,
  isTrialing: false,
  paid: false,
  cryptoPaid: false,
  loading: true,
  loadingWorkspaces: false,
  currentWorkspace: "",
  workspaces: [],
}

function userReducer(state: IUserProps, action: { type: actionTypes; payload?: any }): Partial<IUserProps> {
  switch (action.type) {
    case userActions.SET_USER: {
      const updatedUser: Partial<IUserProps> = { ...state, ...action.payload }
      updatedUser.access = updatedUser?.isAuthenticated
      updatedUser.theme = getGvolTheme(updatedUser?.userTheme)

      return saveAndReturnState(updatedUser)
    }
    case userActions.SET_WORKSPACE_LOADING: {
      return { ...state, loadingWorkspaces: action.payload }
    }
    // TODO: Add New workspace workspace
    case userActions.ADD_WORKSPACE_DASHBOARD: {
      const w = [...state.workspaces, action.payload.workspace]
      const newWorkspaceState: IUserProps = {
        ...state,
        workspaces: w,
        workspaceIds: [...getWorkspaceIds(w)],
        loadingWorkspaces: false,
      }
      return saveAndReturnState(newWorkspaceState)
    }

    // TODO: Add component to workspace
    case userActions.ADD_TO_WORKSPACE: {
      const l = (state?.workspaces || []).map(ws => {
        if (ws?.id === action.payload.workspaceId) {
          return addComponentToWorkspace(ws, action.payload.chartToAdd)
        }
        return ws
      })

      state.workspaces = l
      state.workspaceIds = [...getWorkspaceIds(state.workspaces)]
      return saveAndReturnState({ ...state, loadingWorkspaces: false })
    }

    case userActions.SET_CURRENT_WORKSPACE: {
      const addCurrentWorkspace: IUserProps = {
        ...state,
        currentWorkspace: action.payload.workspaceId,
        loadingWorkspaces: false,
      }
      return saveAndReturnState(addCurrentWorkspace)
    }
    // remove component from dashboard id
    case userActions.REMOVE_FROM_WORKSPACE: {
      const { workspaceId, componentId } = action.payload
      state.workspaces = (state?.workspaces || []).map(ws => {
        if (ws?.id === workspaceId) {
          return removeComponentToSelectedWorkspace(ws, componentId)
        }
        return ws
      })

      state.workspaceIds = [...getWorkspaceIds(state.workspaces)]

      return saveAndReturnState({ ...state, loadingWorkspaces: false })
    }
    // REMOVE ENTIRE DASHBOARD
    case userActions.REMOVE_WORKSPACE_DASHBOARD: {
      const filteredWorkspaces = state.workspaces.filter(obj => obj.id != action.payload.id)
      const removeWorkspaceState: IUserProps = {
        ...state,
        currentWorkspace: filteredWorkspaces?.length > 0 ? filteredWorkspaces?.[0].id : "",
        workspaces: [...filteredWorkspaces],
        loadingWorkspaces: false,
      }
      removeWorkspaceState.workspaceIds = getWorkspaceIds(removeWorkspaceState.workspaces)
      return saveAndReturnState(removeWorkspaceState)
    }

    case userActions.RESET_USER: {
      initialUser.userTheme = "default"
      initialUser.theme = getGvolTheme(initialUser.userTheme)
      return saveAndReturnState(initialUser)
    }

    default: {
      return state
    }
  }
}

function getInitialUser(): Partial<IUserProps> {
  const userString = getItem(USER_KEY)
  if (userString) {
    const user = parseObj(userString)
    if (user) {
      return user
    }
  }
  return initialUser
}
function getUserFromLocalStorage(): Partial<IUserProps> {
  const userString = getItem(USER_KEY)
  if (userString) {
    const user = parseObj(userString)
    if (user) {
      return user
    }
  }
  return initialUser
}

export function getLocalStorageUserOrNull() {
  const userString = getItem(USER_KEY)
  if (userString) {
    const user = parseObj(userString)
    if (user) {
      return user
    }
  }
  return null
}

const subscriptionCheckStatus = new Set(["success", "check"])

export const GlobalUserProvider: FC<any> = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [state, dispatch]: any = useReducer<any>(userReducer, getInitialUser)
  const { auth } = useFirebase()
  const { error } = useNotifications()
  useEffect(() => {
    const getInit = async () => {
      if (isBrowser()) {
        const type = userActions.SET_USER as actionTypes
        try {
          const params = new URLSearchParams(window?.location?.search || "")
          const paramsStatus = params.get("status")

          if (paramsStatus) {
            if (subscriptionCheckStatus.has(paramsStatus)) {
              await updateUserSubscriptionStatus()
            }
            window.history.pushState({}, document.title, window.location.pathname)
          }
          const lsUser = getUserFromLocalStorage()
          const fullUser = await getFullUser()

          dispatch({
            type,
            payload: {
              ...lsUser,
              ...fullUser,
              currentWorkspace: lsUser.currentWorkspace ? lsUser.currentWorkspace : fullUser.currentWorkspace,
            },
          })
          setLoading(false)
        } catch {
          error({ title: "Error Fetching User." })
          dispatch({
            type: userActions.RESET_USER,
          })
          setLoading(false)
        }
      }
    }
    /* eslint-disable  @typescript-eslint/no-unused-vars */
    onAuthStateChanged(auth, async user => {
      if (isBrowser()) {
        if (!loading) {
          setLoading(true)
        }

        await getInit()
      }
    })
    if (isBrowser()) {
      postConsoleMessage()
    }
  }, [])
  useEffect(() => {
    if (!loading) {
      setLoading(true)
      setTimeout(() => setLoading(false), 100)
    }
  }, [state.access])

  return (
    <GlobalUserState.Provider value={state}>
      <GlobalUserDispatch.Provider value={dispatch}>
        {/* <div className="fixed top-14 left-0 bottom-0 right-0 grid place-items-center bg-opacity-70 bg-gradient-to-b from-transparent to-neutral-900 text-neutral-800 [z-index:9999]  cursor-wait">
          <div className=" shadow-md bg-opacity-100 bg-gradient-to-br from-neutral-100 to-neutral-500 px-4 py-2 rounded-md">
            <span className="animate-pulse">Processing...</span>
          </div>
        </div> */}
        {loading ? (
          <LoadingPage
            title={
              <div className=" border-2 border-neutral-300 text-neutral-300 bg-neutral-300  bg-opacity-30 rounded-full px-4 py-2">
                Loading User
              </div>
            }
          />
        ) : (
          children
        )}
      </GlobalUserDispatch.Provider>
    </GlobalUserState.Provider>
  )
}
