export const baseColors = {
  amberDataDarkBlue: {
    "50": "#0055AB",
    "100": "#0052A3",
    "200": "#004A94",
    "300": "#004285",
    "400": "#003B75",
    "500": "#003366",
    "600": "#002E5C",
    "700": "#002952",
    "800": "#002447",
    "900": "#001F3D",
  },
  amberDataOrange: {
    "50": "#FCDECF",
    "100": "#FBD0BC",
    "200": "#F9B696",
    "300": "#F79B6F",
    "400": "#F48049",
    "500": "#F26522",
    "600": "#D04B0C",
    "700": "#9B3809",
    "800": "#662506",
    "900": "#311203",
  },
  amberDataLightBlue: {
    "50": "#F5FAFF",
    "100": "#EBF5FF",
    "200": "#D6EBFF",
    "300": "#C2E0FF",
    "400": "#ADD6FF",
    "500": "#99CCFF",
    "600": "#85C2FF",
    "700": "#70B8FF",
    "800": "#5CADFF",
    "900": "#47A3FF",
  },
  green: {
    "50": "#A2F6C8",
    "100": "#8FF5BC",
    "200": "#6AF1A6",
    "300": "#45EE90",
    "400": "#1FEA7A",
    "500": "#13CE66",
    "600": "#0E9B4D",
    "700": "#0A6733",
    "800": "#05341A",
    "900": "#000100",
  },
  deribitGreen: {
    "50": "#88FFF5",
    "100": "#73FFF4",
    "200": "#4AFFF0",
    "300": "#22FFED",
    "400": "#00F8E3",
    "500": "#00CFBE",
    "600": "#00978B",
    "700": "#005F57",
    "800": "#002724",
    "900": "#000000",
  },
  bitmexRed: {
    "50": "#fff0f0",
    "100": "#ffdddd",
    "200": "#ffc0c0",
    "300": "#ff9494",
    "400": "#ff5857",
    "500": "#ff2423",
    "600": "#ff0302",
    "700": "#d70100",
    "800": "#b10403",
    "900": "#920b0a",
    "950": "#500000",
  },
  huobiBlue: {
    "50": "#f0f9ff",
    "100": "#e0f2fe",
    "200": "#b9e6fe",
    "300": "#7cd2fd",
    "400": "#36bdfa",
    "500": "#0ca5eb",
    "600": "#008cd6",
    "700": "#0168a3",
    "800": "#065886",
    "900": "#0b496f",
    "950": "#072f4a",
  },
  krakenPurple: {
    "50": "#eff2fe",
    "100": "#e2e6fd",
    "200": "#cad0fb",
    "300": "#aab1f7",
    "400": "#8889f1",
    "500": "#756ce8",
    "600": "#5741d8",
    "700": "#5641c1",
    "800": "#46379c",
    "900": "#3d337c",
    "950": "#251e48",
  },
  orange: {
    "50": "#FFFFFF",
    "100": "#FFF1EC",
    "200": "#FFD3C3",
    "300": "#FFB59B",
    "400": "#FF9672",
    "500": "#FF7849",
    "600": "#FF4E11",
    "700": "#D83800",
    "800": "#A02900",
    "900": "#681B00",
  },
  red: {
    "50": "#FBDCE4",
    "100": "#F9CAD6",
    "200": "#F5A5BA",
    "300": "#F1819E",
    "400": "#ED5C81",
    "500": "#E93765",
    "600": "#D11747",
    "700": "#9E1136",
    "800": "#6C0C25",
    "900": "#390613",
  },
  pink: {
    "50": "#FFFFFF",
    "100": "#FDF4F8",
    "200": "#F5D2E3",
    "300": "#EEB0CD",
    "400": "#E78FB8",
    "500": "#E06DA3",
    "600": "#D63F86",
    "700": "#B6276A",
    "800": "#881D4F",
    "900": "#5A1334",
  },
  oldHotPink: {
    "50": "#FFFCFD",
    "100": "#FFE7F2",
    "200": "#FFBEDA",
    "300": "#FF96C3",
    "400": "#FF6DAC",
    "500": "#FF4495",
    "600": "#FF0C75",
    "700": "#D3005B",
    "800": "#9B0043",
    "900": "#63002B",
  },
  hotPink: {
    "50": "#F2C8DB",
    "100": "#EEB7D0",
    "200": "#E696BB",
    "300": "#DF75A6",
    "400": "#D75490",
    "500": "#CF337B",
    "600": "#A32660",
    "700": "#761C45",
    "800": "#49112B",
    "900": "#1B0610",
  },
  lightPink: {
    "50": "#E8D2DD",
    "100": "#E1C4D3",
    "200": "#D3A9BE",
    "300": "#C68EAA",
    "400": "#B87395",
    "500": "#AA5881",
    "600": "#864465",
    "700": "#613149",
    "800": "#3B1E2D",
    "900": "#160B11",
  },
  jaguar: {
    "50": "#B885F7",
    "100": "#AD72F6",
    "200": "#974BF3",
    "300": "#8025F1",
    "400": "#6C0EDF",
    "500": "#590CB8",
    "600": "#470992",
    "700": "#34076C",
    "800": "#220445",
    "900": "#0F021F",
  },
  bitcomGreen: {
    "50": "#FEFFFA",
    "100": "#FAFFE8",
    "200": "#F3FFC4",
    "300": "#EBFEA1",
    "400": "#E4FE7D",
    "500": "#DCFE5A",
    "600": "#D1FE27",
    "700": "#BFF101",
    "800": "#97BE01",
    "900": "#6E8B01",
  },
  lyraGreen: {
    "50": "#F7FDFC",
    "100": "#E6FAF5",
    "200": "#C5F3E8",
    "300": "#A3EBDA",
    "400": "#82E4CD",
    "500": "#60DDBF",
    "600": "#32D3AC",
    "700": "#24A989",
    "800": "#1A7B63",
    "900": "#104C3E",
  },
  premiaBlue: {
    "50": "#FFFFFF",
    "100": "#F5F9FF",
    "200": "#CCE0FF",
    "300": "#A4C6FF",
    "400": "#7BADFF",
    "500": "#5294FF",
    "600": "#1A71FF",
    "700": "#0056E1",
    "800": "#0040A9",
    "900": "#002B71",
  },
  bybitYellow: {
    "50": "#FFE5B0",
    "100": "#FFDE9B",
    "200": "#FFD172",
    "300": "#FFC44A",
    "400": "#FFB621",
    "500": "#F7A600",
    "600": "#BF8000",
    "700": "#875B00",
    "800": "#4F3500",
    "900": "#170F00",
  },
  coinflexPurple: {
    "50": "#FFFFFF",
    "100": "#F7F4FE",
    "200": "#D9CEFC",
    "300": "#BCA7F9",
    "400": "#9E81F7",
    "500": "#815BF4",
    "600": "#5926F0",
    "700": "#3E0ED1",
    "800": "#2F0A9C",
    "900": "#1F0768",
  },
  ftxBlue: {
    "50": "#7EEBFE",
    "100": "#6AE8FD",
    "200": "#41E2FD",
    "300": "#19DBFD",
    "400": "#02C9EA",
    "500": "#02A6C2",
    "600": "#01768A",
    "700": "#014753",
    "800": "#00171B",
    "900": "#000000",
  },
  dydxBlue: {
    "50": "#FFFFFF",
    "100": "#FFFFFF",
    "200": "#E1E0FF",
    "300": "#B9B8FF",
    "400": "#918FFF",
    "500": "#6966FF",
    "600": "#322EFF",
    "700": "#0500F5",
    "800": "#0400BD",
    "900": "#030085",
  },
  shieldRed: {
    "50": "#F3989B",
    "100": "#F18589",
    "200": "#ED6166",
    "300": "#E83C42",
    "400": "#E21A21",
    "500": "#BD161C",
    "600": "#8B1015",
    "700": "#580A0D",
    "800": "#260406",
    "900": "#000000",
  },
  binanceYellow: {
    "50": "#FCEBB7",
    "100": "#FBE6A3",
    "200": "#F9DB7C",
    "300": "#F7D055",
    "400": "#F5C62E",
    "500": "#F0B90B",
    "600": "#BA9009",
    "700": "#856606",
    "800": "#4F3D04",
    "900": "#191401",
  },
  squeethBlue: {
    "50": "#DFFBFE",
    "100": "#CBF9FE",
    "200": "#A3F4FC",
    "300": "#7BEFFB",
    "400": "#54EBFA",
    "500": "#2CE6F9",
    "600": "#07D2E6",
    "700": "#05A0B0",
    "800": "#036E79",
    "900": "#023D43",
  },
  blue: {
    "50": "#D7F2FF",
    "100": "#C2EBFF",
    "200": "#99DEFF",
    "300": "#71D1FF",
    "400": "#48C3FF",
    "500": "#1FB6FF",
    "600": "#009BE6",
    "700": "#0075AE",
    "800": "#004F76",
    "900": "#002A3E",
  },
  okexOrange: {
    50: "#FCE4BB",
    100: "#FBDCA8",
    200: "#FACD81",
    300: "#F8BD59",
    400: "#F7AE32",
    500: "#F59E0B",
    600: "#C07C08",
    700: "#8A5906",
    800: "#543603",
    900: "#1E1401",
  },
  cmeGreen: {
    "50": "#edfff5",
    "100": "#d5ffeb",
    "200": "#aeffd7",
    "300": "#70ffb9",
    "400": "#2bfd95",
    "500": "#00e473",
    "600": "#00c05c",
    "700": "#00964b",
    "800": "#06753f",
    "900": "#076036",
    "950": "#00371c",
  },
  okexPurple: {
    "50": "#fdf5fe",
    "100": "#fbebfc",
    "200": "#f7d5f9",
    "300": "#f3b4f3",
    "400": "#eb87ea",
    "500": "#db53da",
    "600": "#c039bc",
    "700": "#9f2c99",
    "800": "#82267c",
    "900": "#6b2465",
    "950": "#460c41",
  },
  friktionPurple: {
    "50": "#FFFFFF",
    "100": "#FBFBFE",
    "200": "#DED8F9",
    "300": "#C0B4F3",
    "400": "#A291EE",
    "500": "#846EE8",
    "600": "#5B3EE0",
    "700": "#3E1FC6",
    "800": "#2F1896",
    "900": "#201066",
  },
  ledgerXRed: {
    "50": "#F6A2A9",
    "100": "#F48F97",
    "200": "#F06A75",
    "300": "#ED4553",
    "400": "#E92030",
    "500": "#CC1423",
    "600": "#990F1A",
    "700": "#660A11",
    "800": "#330509",
    "900": "#000000",
  },
  deltaExchangeBerry: {
    "50": "#FCA3D5",
    "100": "#FC97D3",
    "200": "#FB7ED2",
    "300": "#FA66D5",
    "400": "#FA4DDB",
    "500": "#F934E5",
    "600": "#F925F3",
    "700": "#ED16F8",
    "800": "#DA07F8",
    "900": "#BC07E9",
  },
  videoPurple: {
    "50": "#FFFFFF",
    "100": "#F3F0FE",
    "200": "#D6CBFA",
    "300": "#B8A5F6",
    "400": "#9B80F3",
    "500": "#7E5BEF",
    "600": "#5628EA",
    "700": "#3E13C6",
    "800": "#2E0E93",
    "900": "#1E0960",
  },
  yellow: {
    "50": "#FFF8E4",
    "100": "#FFF3CF",
    "200": "#FFE8A6",
    "300": "#FFDD7E",
    "400": "#FFD355",
    "500": "#FFC82C",
    "600": "#F3B400",
    "700": "#BB8A00",
    "800": "#836100",
    "900": "#4B3700",
  },
  teal: {
    "50": "#C2EAEB",
    "100": "#B3E5E6",
    "200": "#94DADB",
    "300": "#75D0D1",
    "400": "#57C5C7",
    "500": "#3DB6B8",
    "600": "#2F8C8E",
    "700": "#216364",
    "800": "#13393A",
    "900": "#050F0F",
  },
  greenYellow: {
    "50": "#F5FFE1",
    "100": "#EDFFCC",
    "200": "#DFFFA4",
    "300": "#D1FF7B",
    "400": "#C3FF52",
    "500": "#B0FF1A",
    "600": "#93E100",
    "700": "#6EA900",
    "800": "#4A7100",
    "900": "#253900",
  },
  "pumpkin-orange": {
    "50": "#FFD2B8",
    "100": "#FFC5A3",
    "200": "#FFAB7A",
    "300": "#FF9252",
    "400": "#FF7829",
    "500": "#FF5E00",
    "600": "#C74900",
    "700": "#8F3500",
    "800": "#572000",
    "900": "#1F0B00",
  },
  bgs: {
    "50": "#fafaf9",
    "100": "#f5f5f4",
    "200": "#e7e5e4",
    "300": "#d6d3d1",
    "400": "#a8a29e",
    "500": "#78716c",
    "600": "#57534e",
    "700": "#44403c",
    "800": "#292524",
    "900": "#1c1917",
  },
  aqua: {
    "50": "#E3FBFF",
    "100": "#CEF7FF",
    "200": "#A5F1FF",
    "300": "#7DEBFF",
    "400": "#54E4FF",
    "500": "#2BDEFF",
    "600": "#00CCF2",
    "700": "#009DBA",
    "800": "#006E82",
    "900": "#003E4A",
  },
  darkerBg: {
    "50": "#fafaf9",
    "100": "#f5f5f4",
    "200": "#e7e5e4",
    "300": "#d6d3d1",
    "400": "#737373",
    "500": "#5F5F5F",
    "600": "#4A4A4A",
    "700": "#363636",
    "800": "#212121",
    "900": "#0D0D0D",
  },
  neonBlue: {
    "50": "#E6F4FF",
    "100": "#D1EAFF",
    "200": "#A8D8FF",
    "300": "#80C6FF",
    "400": "#57B3FF",
    "500": "#2EA1FF",
    "600": "#0087F5",
    "700": "#0068BD",
    "800": "#004985",
    "900": "#002A4D",
  },
  thalexBlue: {
    50: "#e8f7ff",
    100: "#d5efff",
    200: "#b3dfff",
    300: "#85c7ff",
    400: "#56a0ff",
    500: "#2f79ff",
    600: "#0c4bff",
    700: "#0040ff",
    800: "#063bcd",
    900: "#103a9f",
    950: "#0a205c",
  },
  neonPink: {
    "50": "#FED2F2",
    "100": "#FEBEEC",
    "200": "#FD96E1",
    "300": "#FC6ED5",
    "400": "#FB46CA",
    "500": "#FA1EBE",
    "600": "#DB05A1",
    "700": "#A40478",
    "800": "#6D0250",
    "900": "#360128",
  },
  neonGreen: {
    "50": "#CFF7C7",
    "100": "#C0F4B5",
    "200": "#A2EF91",
    "300": "#84EA6D",
    "400": "#66E54A",
    "500": "#48E026",
    "600": "#36B41A",
    "700": "#278313",
    "800": "#19520C",
    "900": "#0A2105",
  },
  neonYellow: {
    "50": "#FDFCD9",
    "100": "#FCFAC6",
    "200": "#FAF79F",
    "300": "#F9F478",
    "400": "#F7F051",
    "500": "#F5ED2A",
    "600": "#DDD40A",
    "700": "#A7A108",
    "800": "#716D05",
    "900": "#3C3A03",
  },
  neonPurple: {
    "50": "#F1B8FF",
    "100": "#EDA3FF",
    "200": "#E47AFF",
    "300": "#DC52FF",
    "400": "#D429FF",
    "500": "#CC00FF",
    "600": "#9F00C7",
    "700": "#72008F",
    "800": "#450057",
    "900": "#18001F",
  },
  neonOrange: {
    "50": "#FECEBC",
    "100": "#FEBFA8",
    "200": "#FDA280",
    "300": "#FD8458",
    "400": "#FC672F",
    "500": "#FC4907",
    "600": "#C83802",
    "700": "#912802",
    "800": "#5A1901",
    "900": "#220A00",
  },
  neonRed: {
    "50": "#FFBCB8",
    "100": "#FFA9A3",
    "200": "#FF827A",
    "300": "#FF5C52",
    "400": "#FF3529",
    "500": "#FF0F00",
    "600": "#C70C00",
    "700": "#8F0800",
    "800": "#570500",
    "900": "#1F0200",
  },
  fabioYellow: {
    "50": "#FFFFFF",
    "100": "#FEFFF8",
    "200": "#F8FED1",
    "300": "#F3FCA9",
    "400": "#EDFB82",
    "500": "#E7FA5A",
    "600": "#DFF824",
    "700": "#C4DD07",
    "800": "#93A705",
    "900": "#637003",
  },
  fabioGold: {
    "50": "#FFFDF5",
    "100": "#FEF8E2",
    "200": "#FCEFBB",
    "300": "#FAE694",
    "400": "#F8DC6D",
    "500": "#F6D346",
    "600": "#F3C611",
    "700": "#C29E09",
    "800": "#8D7207",
    "900": "#574704",
  },
  fabioOrange: {
    "50": "#FFF9F0",
    "100": "#FEF0DC",
    "200": "#FDDFB4",
    "300": "#FDCF8C",
    "400": "#FCBE64",
    "500": "#FBAD3C",
    "600": "#FA9605",
    "700": "#C37504",
    "800": "#8C5403",
    "900": "#553302",
  },
  fabioOrangeGold: {
    "50": "#FEF8F4",
    "100": "#FEECE1",
    "200": "#FCD4BA",
    "300": "#FABC93",
    "400": "#F8A36C",
    "500": "#F68B45",
    "600": "#F36A0F",
    "700": "#C15209",
    "800": "#8C3B07",
    "900": "#562504",
  },
  fabioTile: {
    "50": "#FEFCFB",
    "100": "#FBECEA",
    "200": "#F5CDC8",
    "300": "#EEAFA6",
    "400": "#E89083",
    "500": "#E17161",
    "600": "#D84732",
    "700": "#B03321",
    "800": "#812619",
    "900": "#521810",
  },
  fabioPink: {
    "50": "#F5E7EA",
    "100": "#F0D9DE",
    "200": "#E4BBC5",
    "300": "#D89EAB",
    "400": "#CD8192",
    "500": "#C16479",
    "600": "#A9445B",
    "700": "#813445",
    "800": "#592430",
    "900": "#31141A",
  },
  fabioPlum: {
    "50": "#E2CCDB",
    "100": "#DBBFD2",
    "200": "#CCA5BF",
    "300": "#BE8BAD",
    "400": "#AF719A",
    "500": "#9E5987",
    "600": "#7A4568",
    "700": "#56314A",
    "800": "#321C2B",
    "900": "#0E080C",
  },
  fabioDarkBlue: {
    "50": "#0D9AE0",
    "100": "#0C8DCD",
    "200": "#0A72A7",
    "300": "#085880",
    "400": "#053D5A",
    "500": "#032333",
    "600": "#000000",
    "700": "#000000",
    "800": "#000000",
    "900": "#000000",
  },
  fabioNavyBlue: {
    "50": "#4284E6",
    "100": "#3079E4",
    "200": "#1B64D0",
    "300": "#1653AC",
    "400": "#124188",
    "500": "#0D3064",
    "600": "#071832",
    "700": "#000001",
    "800": "#000000",
    "900": "#000000",
  },
  fabioPurple: {
    "50": "#A5A3E1",
    "100": "#9694DC",
    "200": "#7875D2",
    "300": "#5956C9",
    "400": "#403CBA",
    "500": "#35329B",
    "600": "#262471",
    "700": "#181746",
    "800": "#09091C",
    "900": "#000000",
  },
  fabioViolet: {
    "50": "#C0B0DF",
    "100": "#B4A1D9",
    "200": "#9D84CD",
    "300": "#8667C1",
    "400": "#6F4AB6",
    "500": "#5D3E99",
    "600": "#452E71",
    "700": "#2C1E49",
    "800": "#140D21",
    "900": "#000000",
  },
  fabioPinkPurple: {
    "50": "#D2BADA",
    "100": "#C9ADD3",
    "200": "#B792C4",
    "300": "#A678B6",
    "400": "#955DA8",
    "500": "#7E4D8F",
    "600": "#5E396B",
    "700": "#3E2646",
    "800": "#1E1222",
    "900": "#000000",
  },
  bitcoin: {
    "50": "#FFE6AB",
    "100": "#FFDF96",
    "200": "#FFD36D",
    "300": "#FFC745",
    "400": "#FFBA1C",
    "500": "#F2A900",
    "600": "#BA8200",
    "700": "#825B00",
    "800": "#4A3300",
    "900": "#120C00",
  },
  ethereum: {
    "50": "#E0F6F9",
    "100": "#CFF1F5",
    "200": "#ADE8EE",
    "300": "#8CDEE7",
    "400": "#6AD5E0",
    "500": "#48CBD9",
    "600": "#28B2C1",
    "700": "#1E8792",
    "800": "#155C64",
    "900": "#0B3135",
  },
  solana: {
    "50": "#F9D7FF",
    "100": "#F6C2FF",
    "200": "#EF99FF",
    "300": "#E971FF",
    "400": "#E248FF",
    "500": "#DC1FFF",
    "600": "#C200E6",
    "700": "#9300AE",
    "800": "#630076",
    "900": "#34003E",
  },
  xrp: {
    "50": "#edfbff",
    "100": "#d6f5ff",
    "200": "#b5f0ff",
    "300": "#83e9ff",
    "400": "#48daff",
    "500": "#1ebfff",
    "600": "#06a3ff",
    "700": "#008ffe",
    "800": "#086dc5",
    "900": "#0d5d9b",
    "950": "#0e385d",
  },
  amberDataLightGrey: {
    "50": "#FAFAFA",
    "100": "#F7F7F7",
    "200": "#EFEFEF",
    "300": "#E7E7E7",
    "400": "#E0E0E0",
    "500": "#D8D8D8",
    "600": "#C9C9C9",
    "700": "#B9B9B9",
    "800": "#AAAAAA",
    "900": "#9B9B9B",
  },
  amberDataDarkGrey: {
    "50": "#BFBFBF",
    "100": "#B5B5B5",
    "200": "#A1A1A1",
    "300": "#8C8C8C",
    "400": "#787878",
    "500": "#636363",
    "600": "#4A4A4A",
    "700": "#303030",
    "800": "#171717",
    "900": "#000000",
  },
  amberDataBgs: {
    "50": "#FFFFFF",
    "100": "#ECECEC",
    "200": "#D8D8D8",
    "300": "#BCBCBC",
    "400": "#A0A0A0",
    "500": "#7D7D7D",
    "600": "#686868",
    "700": "#545454",
    "800": "#404040",
    "900": "#171717",
  },
  amberDataPink: {
    "50": "#FFC0E8",
    "100": "#FFACE1",
    "200": "#FF83D2",
    "300": "#FF5AC3",
    "400": "#FF32B4",
    "500": "#FF09A6",
    "600": "#DF008E",
    "700": "#AC006E",
    "800": "#79004D",
    "900": "#46002D",
  },
  amberDataTeal: {
    "50": "#EEFBFE",
    "100": "#E0F8FD",
    "200": "#C3F2FB",
    "300": "#A6ECF9",
    "400": "#8AE6F7",
    "500": "#6DE0F5",
    "600": "#42D7F2",
    "700": "#17CEEF",
    "800": "#0EADCA",
    "900": "#0B899F",
  },
  amberDataRed: {
    "50": "#F9C7D0",
    "100": "#F7B2BE",
    "200": "#F3899B",
    "300": "#EF5F78",
    "400": "#EB3554",
    "500": "#DC1638",
    "600": "#AE112C",
    "700": "#7F0D20",
    "800": "#510815",
    "900": "#230309",
  },
  amberDataPurple: {
    "50": "#D4BBED",
    "100": "#CAABE9",
    "200": "#B68BE1",
    "300": "#A26AD8",
    "400": "#8D4AD0",
    "500": "#7932BF",
    "600": "#5F2797",
    "700": "#461D6E",
    "800": "#2C1246",
    "900": "#13081D",
  },
  amberDataGreen: {
    "50": "#ECFFF3",
    "100": "#D5FFE5",
    "200": "#A7FFC8",
    "300": "#79FFAB",
    "400": "#4BFF8F",
    "500": "#1DFF72",
    "600": "#00E958",
    "700": "#00B644",
    "800": "#008331",
    "900": "#00501E",
  },
  amberDataLavender: {
    "50": "#EEE9F6",
    "100": "#E2D9EF",
    "200": "#C9B9E1",
    "300": "#B198D3",
    "400": "#9878C6",
    "500": "#8058B8",
    "600": "#67429B",
    "700": "#4F3377",
    "800": "#382454",
    "900": "#201430",
  },
  amberDataYellow: {
    "50": "#FFFFE8",
    "100": "#FFFECA",
    "200": "#FFFE8C",
    "300": "#FFFD4F",
    "400": "#FFFC12",
    "500": "#F2EF00",
    "600": "#D4D100",
    "700": "#B5B300",
    "800": "#979500",
    "900": "#787600",
  },
  paradigmPurple: {
    50: "#C7BAEC",
    100: "#BAAAE7",
    200: "#A18ADE",
    300: "#876AD5",
    400: "#6D4ACC",
    500: "#5834BA",
    600: "#43288E",
    700: "#2F1B62",
    800: "#1A0F36",
    900: "#05030B",
  },
}

export const colorOptions = {
  ...baseColors,
  primary: baseColors.amberDataDarkBlue,
  secondary: baseColors.amberDataLightBlue,
  highlight: baseColors.amberDataOrange,
  lightBgs: baseColors.amberDataLightGrey,
  darkBgs: baseColors.amberDataDarkGrey,
}
export default colorOptions
